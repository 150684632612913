import {
  isOperating,
  setDuplicateEmail,
  setEmailVerificationRequest,
} from '##/state/registration/actions';
import { updateTrackerUserId } from '##/utils/tracking';
import {
  changePasswordWithGigya,
  deleteProfile,
  updateProfileWithGigya,
  logoutFromAllDevices,
} from '##/utils/user';
import { Dispatch } from 'redux';
import {
  setError,
  setProfileIncomplete,
  updateProfile,
  setStatusCode,
  setChangePassword,
} from '../actions';
import { userName } from '../types';
import { DUPLICATE_EMAIL_STATUS_DETAIL } from './../types';
import { IAuthData, getToken } from '##/utils/auth/authManager';
import { getLoginDataFromLocalStorage } from '##/utils/auth/authLocalStorage';
import { showSidebarMenu } from '##/state/sidebarMenu';
import aamTracker from '##/utils/tracking/aam-tracker';
import { logout } from './logout';

export const mergeAuthAndGigyaResponse = (authResponse, gigyaData) => {
  const user = gigyaData;

  /* AUTH getProfile only returns email, birthYear, firstName and gender.
   * Also, for example, it doesn't return gender if gender
   * doesn't exist on auth. Hence, it's necessary to reconstruct
   * the object as below: */
  const authProfile = {
    birthYear: authResponse.birthYear || null,
    email: authResponse.email || null,
    firstName: authResponse.firstName || user.firstName || null,
    gender: authResponse.gender || null,
    hasFullAccountProfile: authResponse.hasFullAccountProfile || null,
    postcode: authResponse.postcode || null,
  };

  const currentProfile = {
    ...user,
    ...authProfile,
  };

  return currentProfile;
};

export const mapGigyaResponse = ({ UID, isVerified, profile, data }) => {
  if (!profile) {
    return {
      isVerified,
      id: UID,
    };
  }

  aamTracker.setId(UID);

  return {
    birthYear: profile.birthYear,
    email: profile.email,
    firstName: profile.firstName,
    gender: profile.gender,
    id: UID,
    imageUrl: profile.thumbnailURL,
    isVerified,
    lastName: profile.lastName,
    postcode: profile.postcode,
    verificationRedirectURL: data.emailverificationredirecturl,
  };
};

export const updateTrackerUser = (user) => {
  if (!user) {
    user = { id: null, firstName: null, imageUrl: null };
  }

  updateTrackerUserId(user.id || null);

  return user;
};

export const handleSidebar =
  (dispatch: Dispatch) =>
  ({ profileIncomplete }) => {
    if (profileIncomplete) {
      // user profile data is not complete, then it'll display the ProfileIncompleteSidebar
      dispatch(setProfileIncomplete(true));
    }
  };

export const checkIfPasswordRequired = async (dispatch: Dispatch) => {
  const { hasFullProfileAccount } = getLoginDataFromLocalStorage();
  if (!hasFullProfileAccount) {
    console.log('checkIfPasswordRequired');
    dispatch(showSidebarMenu());
    return hasFullProfileAccount;
  }
};

/**
 * Updates the user profile in gigya, redux and localstorage.
 *
 * @param {{ birthYear, email, firstName, gender, postcode, setSubmitting }} data Contains user information to be
 * updated jointly with setSubmitting.
 *
 * setSubmitting is a Formik function that needs to be called to indicate that
 * submission has finished. Needs to be called here, instead of inside of the registration form
 * since once isOperating(false) is called will trigger to close the registration form, causing
 * a memory leak (since we'd have an active handler there after the component had been unmounted)
 */
export const updateAllProfile =
  (data) => async (dispatch: Dispatch, getState) => {
    const {
      birthYear,
      email,
      firstName,
      gender,
      lastName,
      postcode,
      setSubmitting,
      editProfile,
    } = data;
    let errors;
    let status;

    try {
      dispatch(isOperating(true));

      const update: Partial<IAuthData> = {
        birthYear,
        firstName,
        gender,
        lastName,
        postcode,
      };

      // prevent updating the user email if it is already exist
      const userEmail = getState()[userName].email;
      if (!userEmail || userEmail !== email) {
        update.email = email;
      }

      ({ Errors: errors, status } = await updateProfileWithGigya(update));

      if (errors) {
        // TODO: Add node backend validation error handling for each field.
        errors.forEach((error) => {
          if (error.Detail === DUPLICATE_EMAIL_STATUS_DETAIL) {
            dispatch(setDuplicateEmail(true));
          }
        });
      } else {
        if (update.email) {
          // if there was an update in email, then we should verify it
          dispatch(setEmailVerificationRequest(true));
        }

        // update redux store and localstorage
        dispatch(
          updateProfile({
            ...update,
            profileIncomplete: false,
            statusCode: status,
            editProfile,
          }),
        );
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      // important to call it before isOperating(false), see function comment above.
      setSubmitting(false);
      dispatch(isOperating(false));
    }
  };

export const deleteAllProfile =
  (data, ownProps) => async (dispatch: Dispatch, getState) => {
    const { setSubmitting } = data;

    const token = await getToken(dispatch);

    try {
      dispatch(isOperating(true));
      const res = await deleteProfile(token.token);
      dispatch(setStatusCode(res.status));
      await logoutFromAllDevices(token.token);
      await logout(ownProps.history, dispatch, data.provider, false, true);
    } catch (error) {
      dispatch(setError(error));
    } finally {
      // important to call it before isOperating(false), see function comment above.
      setSubmitting(false);
      dispatch(isOperating(false));
    }
  };

/* istanbul ignore next */
export const changePassword =
  (data) => async (dispatch: Dispatch, getState) => {
    const {
      oldPassword,
      newPassword,
      changePassword,
      isPasswordChange,
      setSubmitting,
    } = data;

    try {
      const update: Partial<any> = {
        oldPassword,
        newPassword,
        changePassword,
      };

      const res = await changePasswordWithGigya(update);
      dispatch(setStatusCode(res.status));
      if (isPasswordChange && res.status === 'OK') {
        dispatch(setChangePassword(true));
      }
    } catch (error) {
      if (
        error.message === 'Error: Gigya error: Invalid LoginID code: 403042'
      ) {
        dispatch(setError({ errorCode: 403042 }));
      } else if (
        error.message ===
        'Error: Gigya error: Invalid parameter value code: 400006'
      ) {
        dispatch(setError({ errorCode: 400006 }));
      }
    } finally {
      // important to call it before isOperating(false), see function comment above.
      setSubmitting(false);
      dispatch(isOperating(false));
    }
  };
