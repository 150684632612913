export interface IIconNames {
  EXTERNAL_LINK: string;
  PAUSE: string;
  PLAY: string;
  STOP: string;
  EE_PLAY: string;
  CC: string;
  FULLSCREEN: string;
  LEFT_ARROW: string;
  LOGO: string;
  MUTE: string;
  UNMUTE: string;
  NEW_PAUSE: string;
  SETTINGS: string;
  NEW_PLAY: string;
  ADD_ICON: string;
  REMOVE_ICON: string;
  RED_CLOSE_ICON: string;
  RED_ADD_ICON: string;
  RED_REMOVE_ICON: string;
  AFL_ICON: string;
}

export const IconNames: IIconNames = {
  EXTERNAL_LINK: 'externalLink',
  PAUSE: 'pause',
  PLAY: 'play',
  STOP: 'stop',
  EE_PLAY: 'eePlay',
  CC: 'cc',
  FULLSCREEN: 'fullscreen',
  LEFT_ARROW: 'leftArrow',
  LOGO: 'logo',
  MUTE: 'mute',
  UNMUTE: 'unmute',
  NEW_PAUSE: 'eePause',
  SETTINGS: 'settings',
  NEW_PLAY: 'newPlay',
  ADD_ICON: 'add',
  REMOVE_ICON: 'remove',
  RED_ADD_ICON: 'redAdd',
  RED_REMOVE_ICON: 'redRemove',
  RED_CLOSE_ICON: 'redClose',
  AFL_ICON: 'aflIcon',
};
