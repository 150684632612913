import React from 'react';

const AFLIcon = ({ width = 76, height = 41 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 76 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0012 0C17.0127 0 0 9.17831 0 20.5C0 31.8217 17.0161 41 38.0012 41C58.9862 41 76 31.8217 76 20.5C76 9.17831 58.9897 0 38.0012 0ZM38 40.4306C17.7796 40.4306 1.38813 31.4812 1.38813 20.4419C1.38813 9.40267 17.7784 0.455556 38 0.455556C58.2216 0.455556 74.6119 9.40267 74.6119 20.4419C74.6119 31.4812 58.2204 40.4306 38 40.4306Z"
        fill="#F8F8F8"
      />
      <path
        d="M16.5037 26.3441H24.7712L25.4491 29.2699H39.2609V22.4901H46.1021L45.8823 18.4584H39.2609V15.421H49.2427L48.8957 10.9395H32.7853L32.2405 27.5103H32.0091L26.3803 10.9395H15.1746L20.0469 13.15H17.7623L7.63477 29.2699H14.9999L16.5037 26.3441ZM21.8874 15.7171H22.2598L23.9453 23.006H18.1614L21.8874 15.7171Z"
        fill="#F8F8F8"
      />
      <path
        d="M58.865 24.6527L57.3763 13.1499H55.2524L60.4811 10.9336H50.2783L51.737 29.2697H68.597L67.4865 24.6527H58.865Z"
        fill="#F8F8F8"
      />
      <path
        d="M10.2133 22.7679C10.0687 22.0311 9.99583 21.2817 9.99467 20.5312C9.99467 12.4724 18.4241 5.66752 29.9849 3.47744V5.45683H30.7946V3.33166C32.2082 3.09363 33.6333 2.92622 35.0643 2.83169V6.97725H35.8601V2.785C36.5658 2.75197 37.2783 2.73602 37.9967 2.73488C38.7162 2.73488 39.4265 2.75197 40.1321 2.785V6.97725H40.936V2.83169C42.367 2.92622 43.7921 3.09363 45.2057 3.33166V5.45683H46.0154V3.47744C57.5751 5.66752 66.0056 12.4747 66.0056 20.5312C66.0045 21.53 65.8738 22.5254 65.617 23.4923H67.75C68.0045 22.5219 68.1329 21.5231 68.1341 20.5209C68.1341 10.2504 54.6415 1.93652 37.999 1.93652C21.3565 1.93652 7.86621 10.2504 7.86621 20.5209C7.86968 22.0755 8.17738 23.6153 8.77196 25.056L10.2133 22.7679Z"
        fill="#F8F8F8"
      />
      <path
        d="M37.9295 38.2834C29.0616 38.2834 21.1562 35.664 16.0306 31.564H13.7656C19.2545 36.115 28.0344 39.0635 37.9295 39.0635C47.8245 39.0635 56.631 36.1081 62.1187 31.5469H59.8538C54.727 35.6469 46.8112 38.2834 37.9295 38.2834Z"
        fill="#F8F8F8"
      />
      <path
        d="M65.6078 31.5468C65.6078 31.22 65.3603 31.0537 64.864 31.0537L64.1885 31.0583V32.7666H64.6072V32.0673H64.8166L65.1509 32.7666H65.6217L65.2284 32.0024C65.4505 31.9625 65.6113 31.7701 65.6078 31.5468ZM64.8166 31.819H64.6072V31.3225H64.7958C65.0514 31.3225 65.1764 31.3988 65.1764 31.5765C65.1764 31.7541 65.0607 31.819 64.8166 31.819Z"
        fill="#F8F8F8"
      />
      <path
        d="M64.8301 30.4083C64.2876 30.4015 63.7821 30.6817 63.5067 31.1429C63.3564 31.3855 63.2765 31.6634 63.2754 31.947C63.2754 32.3479 63.4362 32.7328 63.7207 33.0187C64.0111 33.3148 64.4113 33.4822 64.8289 33.4833C65.2442 33.4822 65.6433 33.3205 65.9394 33.0346C66.5433 32.4378 66.5502 31.4755 65.9556 30.8707C65.6595 30.5735 65.2535 30.406 64.8301 30.4083ZM66.1407 31.9663C66.1407 32.3 66.0019 32.62 65.7578 32.8524C65.5103 33.0904 65.1783 33.2248 64.8324 33.2294C64.1152 33.2202 63.538 32.6474 63.5357 31.9413C63.538 31.595 63.6803 31.2648 63.9301 31.021C64.1696 30.7864 64.4958 30.6566 64.8336 30.6623C65.5484 30.6555 66.1338 31.2204 66.1407 31.9242C66.1407 31.9379 66.1407 31.9527 66.1407 31.9663Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default React.memo(AFLIcon);
