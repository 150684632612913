import { logError } from '##/utils/trackJs';

export const getValueFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    logError('Unable to access local storage', error);
    return null;
  }
};

export const getJsonValueFromLocalStorage = <T = unknown>(
  key: string,
): T | null => {
  const value = getValueFromLocalStorage(key);
  if (value === '') {
    return null;
  }
  try {
    return JSON.parse(value) as T;
  } catch {
    logError('Unable to parse JSON from local storage', value);
  }
  return null;
};

export const setValueInLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    logError('Unable to write to local storage', error);
  }
};

export const setJsonValueInLocalStorage = (key: string, value: any) => {
  try {
    setValueInLocalStorage(key, JSON.stringify(value));
  } catch (error) {
    logError('Unable to stringify value', error);
  }
};

export const removeItemInLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    logError('Unable to access local storage', error);
  }
};
