// global styles must come first so components can override them
import './styles/index.pcss';

// polyfills must be loaded before any app code
import './polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { VideoJsPlayer } from 'video.js';

import { AppContainer } from '##/components/AppContainer';
import { IFrontendConfig } from '../interfaces/IConfigService';
import { IGoogleTag } from '##/interfaces/DfpManager';
import { initTrackJs } from '##/utils/trackJs';

// Initalize Track JS error handling
initTrackJs();

declare global {
  interface Window {
    chrome: typeof chrome;
    swm: {
      buildNumber: string;
      cookieSettings: {
        advertising: boolean;
        analytical: boolean;
        essential: boolean;
        modified: number;
      } | null;
      config: IFrontendConfig;
      env: string;
      error: {
        imageUrl: string;
        message: string;
        redirect: {
          link: string;
          message: string;
          timeSec: number;
        };
        status: number;
        title: string;
      } | null;
      versionNumber: string;
      logLevel: string;
      brightcoveConfig: {
        bundleHash: string;
        playerId: string;
        useMinifiedPlayerFile: boolean;
      };
      gigyaSdkUrl: string;
    };
    gigya: {
      accounts: {
        // TODO: fill it correctly gigya.accounts.* according gigya's docs
        logout(): void;
      };
    };
    OVP: any;
    _satellite: {
      track: (eventName: string) => void;
    };
    aamDataLayer: {
      swmV2: string;
      ppid: string;
    };
    googletag: IGoogleTag;
    Lotame?: {
      Profile?: {
        tpid: string;
        pid: string;
        Audiences?: {
          Audience: {
            id: string;
          }[];
        };
      };
    };
    nol_t(_: any): {
      sdkInit(arg0: { cid: string; content: string; server: string }): any;
    };
    MSInputMethodContext?: {}; // type added to check for IE11 in src/utils/userContext.ts
    MSStream?: {}; // type added to check for IE11 in src/utils/userContext.ts
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    videojs: {
      getPlugin(plugin: string): any;
      registerPlugin(plugin: string, any): void;
      getComponent(component: string): any;
      log(_: string): void;
      dom: {
        createEl(el: string, properties: any): HTMLElement;
      };
    };
    player: VideoJsPlayer;
    GcHtml5: any;
    firebase: any;
    __gigyaConf: any;
  }

  interface Document {
    documentMode?: any; // type added to check for IE11 in src/utils/userContext.ts
  }

  interface Navigator {
    msDoNotTrack?: any; // type added to check IE9/IE10 do not track in src/utils/tracking.index.ts
  }
}

// FIXME: Check hot() is correctly removed from production builds. The babel
// plugin _should_ do it.
// FIXME: Modify redux to allow HMR in dev mode
// const App = hot(AppContainer);
// render(<App />, document.getElementById('app'));

// TODO: Try to get HMR working
const root = createRoot(document.getElementById('app'));
root.render(<AppContainer />);
