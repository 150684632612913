import { SET_MARKET_DATA } from '../market/constants';
import {
  SET_IN_PROGRESS,
  SET_DATA,
  SET_GEO_INTENT,
  SET_WITH_LOCATION,
  SET_IGNORE_LOCATION,
  CLEAR_CONNECT_TV,
  SET_CONNECT_TV_ERROR,
  CLEAR_CONNECT_TV_ERROR,
} from './constants';

const initialState = {
  inProgress: false,
  data: null,
  marketData: null,
  connectTv: {
    geoIntent: false,
    location: undefined,
    ignoreLocation: false,
    errorModal: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IN_PROGRESS:
      return { ...state, inProgress: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload };
    case SET_MARKET_DATA:
      return { ...state, marketData: action.payload };
    case SET_GEO_INTENT:
      return {
        ...state,
        connectTv: { ...state.connectTv, geoIntent: action.payload },
      };
    case SET_WITH_LOCATION:
      return {
        ...state,
        connectTv: { ...state.connectTv, location: action.payload },
      };
    case SET_IGNORE_LOCATION:
      return {
        ...state,
        connectTv: { ...state.connectTv, ignoreLocation: action.payload },
      };
    case CLEAR_CONNECT_TV:
      return {
        ...state,
        inProgress: false,
        connectTv: { ...initialState.connectTv },
      };
    case SET_CONNECT_TV_ERROR:
      return {
        ...state,
        inProgress: false,
        connectTv: { ...state.connectTv, errorModal: action.payload },
      };
    case CLEAR_CONNECT_TV_ERROR:
      return {
        ...state,
        connectTv: { ...state.connectTv, errorModal: undefined },
      };
    default:
      return state;
  }
};
