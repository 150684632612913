import { combineReducers } from 'redux';

import adBlockerReducer, { adBlockerName } from './adBlocker';
import currentMediaReducer, { currentMediaName } from './currentMedia';
import marketReducer, { marketName } from './market';
import geocomplyReducer, { geocomplyName } from './geocomply';
import pageReducer, { pageName } from './page';
import playerReducer, { playerName } from './player';
import searchReducer, { searchName } from './search';
import sidebarMenuReducer, { sidebarMenuName } from './sidebarMenu';
import { componentsName } from './components/types';
import { componentsReducer } from './components/reducer';
import { cookieName } from './cookie/types';
import { cookieReducer } from './cookie/reducer';
import { displayAdName } from './displayAd/types';
import { displayAdReducer } from './displayAd/reducer';
import { registrationName } from './registration/types';
import { registrationReducer } from './registration/reducer';
import { userName } from './user/types';
import { userReducer } from './user/reducer';
import { navigation } from './navigation/types';
import { navigationReducer } from './navigation/reducer';
import activeTabReducer, { activeTabName } from './activeTabIndex';
import channelItemHoverIndexReducer, {
  channelItemHoverIndexName,
} from './channelItemHoverIndex';
import { header } from './header/types';
import { headerReducer } from './header/reducer';

const rootReducer = combineReducers({
  [adBlockerName]: adBlockerReducer,
  [componentsName]: componentsReducer,
  [cookieName]: cookieReducer,
  [currentMediaName]: currentMediaReducer,
  [displayAdName]: displayAdReducer,
  [marketName]: marketReducer,
  [geocomplyName]: geocomplyReducer,
  [pageName]: pageReducer,
  [playerName]: playerReducer,
  [registrationName]: registrationReducer,
  [searchName]: searchReducer,
  [sidebarMenuName]: sidebarMenuReducer,
  [userName]: userReducer,
  [navigation]: navigationReducer,
  [activeTabName]: activeTabReducer,
  [channelItemHoverIndexName]: channelItemHoverIndexReducer,
  [header]: headerReducer,
});

export default rootReducer;
