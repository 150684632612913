import {
  endpoints,
  REG_SOURCE,
  WEB_CTV_PLATFORM_ID,
  WEB_PLATFORM_ID,
} from '##/utils/constants';
import axios from 'axios';
import { runGigyaMethod } from './common';
import { IGetAccountInfoResponse } from '##/interfaces/IGetAccountInfoResponse';
import {
  DISABLED_ACCOUNT_ERROR,
  ERROR_DISABLED_ACCOUNT,
  ERROR_INVALID_LOGIN_ID,
  ERROR_OPERATION_CANCELLED,
  INVALID_LOGIN_ID_ERROR_MESSAGE,
} from './constants';

const { authService: authServiceURL } = endpoints;

export const createSignInErrorMessage = (errorCode: number): string | null => {
  if (!errorCode || errorCode === ERROR_OPERATION_CANCELLED) {
    return null;
  }
  if (errorCode === ERROR_INVALID_LOGIN_ID) {
    return INVALID_LOGIN_ID_ERROR_MESSAGE;
  }

  if (errorCode === ERROR_DISABLED_ACCOUNT) {
    return DISABLED_ACCOUNT_ERROR;
  }

  return `Failed to sign in. Please try again later (error ${errorCode})`;
};

export const getGigyaToken = () =>
  runGigyaMethod('getJWT', undefined).then((resp) => resp.id_token);

export const logout = () =>
  runGigyaMethod('logout', { forceProvidersLogout: false });

export const emailLogin = (params) => runGigyaMethod('login', params);

export const getAccountInfo = () =>
  runGigyaMethod(
    'getAccountInfo',
    undefined,
  ) as Promise<IGetAccountInfoResponse>;

export const refreshSession = () => runGigyaMethod('refreshSession', undefined);

/**
 * Notify the core auth service that the user has logged in.
 * checks the path to determine which platform user is logging into
 * This is required so the auth service can increment a user login counter.
 * @param {string} gigyaToken Gigya user JWT.
 * @param {boolean} isConnectDevicePath
 * @return {Promise<string>} The Gigya JWT for use in other login functions.
 */
export const notifyLoggedIn = (gigyaToken, isConnectDevicePath) =>
  axios(`${authServiceURL}/account/info`, {
    method: 'post',
    headers: { authorization: `Bearer ${gigyaToken}` },
    data: {
      PlatformId: isConnectDevicePath ? WEB_CTV_PLATFORM_ID : WEB_PLATFORM_ID,
      RegSource: REG_SOURCE,
    },
  }).then(() => gigyaToken);
