import { makeAction } from '##/utils/redux';
import {
  SET_IN_PROGRESS,
  SET_DATA,
  SET_MARKET_DATA,
  SET_GEO_INTENT,
  SET_WITH_LOCATION,
  SET_IGNORE_LOCATION,
  CLEAR_CONNECT_TV,
  SET_CONNECT_TV_ERROR,
  CLEAR_CONNECT_TV_ERROR,
} from './constants';
import { IGeoErrorType } from '##/state/geocomply/types';

export const setInProgress = (data: boolean) =>
  makeAction(SET_IN_PROGRESS, data);
export const setData = (data) => makeAction(SET_DATA, data);
export const setMarketData = (data) => makeAction(SET_MARKET_DATA, data);
export const setGeoIntent = (data) => makeAction(SET_GEO_INTENT, data);
export const setWithLocation = (data) => makeAction(SET_WITH_LOCATION, data);
export const setIgnoreLocation = (data) =>
  makeAction(SET_IGNORE_LOCATION, data);
export const clearConnectTv = () => makeAction(CLEAR_CONNECT_TV, null);
export const setConnectTvError = (data: IGeoErrorType) =>
  makeAction(SET_CONNECT_TV_ERROR, data);
export const clearConnectTvError = () =>
  makeAction(CLEAR_CONNECT_TV_ERROR, null);
