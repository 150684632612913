import getCapiData from '##/utils/capi';
import trackingInitialiser from '##/utils/tracking/index';
import { getErrorResponseData, isSwmError } from '##/utils/errors';
import { getMarketData } from '##/utils/market';
import { getUrlParam } from '##/utils/getUrlParam';
import { isDevEnvironment } from '##/utils/environment';
import { logError } from '##/utils/trackJs';

const errorPageParam = getUrlParam('errorPage');
const debug = getUrlParam('debug');
const { config, env } = window.swm;

const detectServerError = async () => {
  if (isDevEnvironment(env) && debug && typeof errorPageParam === 'string') {
    throw getErrorResponseData(parseInt(errorPageParam, 10));
  }
  if (window.swm.error) {
    throw window.swm.error;
  }
};

/**
 * Initialise the tracker withmarket data.
 * @param {object} marketData Object containing market data.
 *
 * @returns {object} Object containing the state updates.
 */
const initialiseTracker = (marketData) => {
  trackingInitialiser({
    config,
    postcode: parseInt(marketData.postcode, 10),
  });

  return marketData;
};

const retrieveCapiDataAndStateUpdates =
  (location, authJWTToken) => (marketData) => ({
    // eslint-disable-next-line no-underscore-dangle
    capiDataPromise: getCapiData(marketData.market_id, location, authJWTToken),
    marketData,
  });

const handleRouteChangeError = (error) => {
  let status;

  if (typeof error === 'object') {
    if (typeof error.response === 'object') {
      ({ status } = error.response);
    } else if (error.status) {
      ({ status } = error);
    }
  }

  logError(`App request error [${JSON.stringify(error)}]`);

  let errorData;

  if (isSwmError(error)) {
    errorData = error;
  } else {
    errorData = getErrorResponseData(status || 500);
  }

  return { error: errorData };
};

/**
 * Receives the location a new route change and handled it properly
 * @param {object} location The location object that comes from React Router
 * @param {string} authJWTToken JWT string content, without extra information like profileIncomplete
 *
 * @returns {object} Object containing { capiDataPromise, stateUpdates } when successful,
 *                   otherwise object containing { error }
 */
export const handleRouteChange = (location, authJWTToken) =>
  detectServerError()
    .then(getMarketData)
    .then(initialiseTracker)
    .then(retrieveCapiDataAndStateUpdates(location, authJWTToken))
    .catch(handleRouteChangeError);

export const SIDE_BAR_OVERLAY: string = 'overlay';
export const LOG_OUT: string = 'logout';

interface IPaths {
  CONNECT: string;
  FORGOT_PASSWORD: string;
  NEW_PASSWORD: string;
  REGISTER: string;
  SET_PASSWORD: string;
  SIGN_IN: string;
  UPDATE_PROFILE: string;
  NEW_PASSWORD_LEGACY: string;
  GEOCOMPLY: string;
  GEO_PRE_CONNECT: string;
}

export const PATHS: IPaths = {
  CONNECT: '/connect',
  FORGOT_PASSWORD: 'forgot-password',
  NEW_PASSWORD_LEGACY: '/newpassword',
  REGISTER: 'register',
  SET_PASSWORD: 'set-password',
  SIGN_IN: 'sign-in',
  UPDATE_PROFILE: 'update-profile',
  NEW_PASSWORD: 'new-password',
  GEOCOMPLY: 'geocomply',
  GEO_PRE_CONNECT: 'locationRequired',
};

const isOverlayPath = () =>
  Boolean(
    getUrlParam(`overlay=${PATHS.FORGOT_PASSWORD}`) ||
      getUrlParam(`overlay=${PATHS.REGISTER}`) ||
      getUrlParam(`overlay=${PATHS.SIGN_IN}`) ||
      getUrlParam(`overlay=${PATHS.NEW_PASSWORD}`),
  );

interface IHandleSidebarToggleProps {
  sidebarMenuVisible: boolean;
  showSidebarMenu(): void;
}

export const showSidebarOnRouteChange = ({
  sidebarMenuVisible,
  showSidebarMenu,
}: IHandleSidebarToggleProps): void => {
  if (!sidebarMenuVisible && isOverlayPath()) {
    showSidebarMenu();
  }
};
