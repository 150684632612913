import debounce from 'lodash/debounce';

import { camelizeKeys } from '##/utils/jsonCamelCase';
import { logError } from '##/utils/trackJs';
import { makeAction } from '##/utils/redux';
import { marketName } from '##/state/market';

import {
  API_VERSION,
  API_VERSION_SEARCH,
  CHARACTER_SEARCH_LIMIT,
  SEARCH_RESULTS_LIMIT,
  UPDATE_RESULTS,
  UPDATE_TERM,
} from './constants';

const SEARCH_DEBOUNCE_TIME = 300;

const { config } = window.swm;
const searchApiEndpoint = config.endpoints.searchapi;

let searchDebouncer;

// TODO: stop using 'makeAction' and type the 'player' reducer and actions
//       as 'page' reducer and actions
export const updateSearchTerm = (searchTerm) =>
  makeAction(UPDATE_TERM, searchTerm);

export const updateSearchResults = (searchResults) =>
  makeAction(UPDATE_RESULTS, searchResults);

const fetchSearchResults = (url, dispatch) => async () => {
  try {
    const res = await fetch(url);
    const data = await res.json();

    const camelCaseData = camelizeKeys(data);
    dispatch(updateSearchResults(camelCaseData));
  } catch (error) {
    logError(`Couldn't retrieve any search results: [${error}]`);

    dispatch(updateSearchResults([]));
  }
};

export const search = (searchTerm) => async (dispatch, getState) => {
  if (searchDebouncer) searchDebouncer.cancel();

  await dispatch(updateSearchTerm(searchTerm));
  // eslint-disable-next-line no-underscore-dangle
  const marketId = getState()[marketName].data.market_id;

  // Return if three or more characters are not entered
  if (searchTerm.length < CHARACTER_SEARCH_LIMIT) {
    return dispatch(updateSearchResults([]));
  }

  const addVersion = searchApiEndpoint.replace('{apiVersion}', API_VERSION);

  searchDebouncer = debounce(
    fetchSearchResults(
      `${addVersion}${encodeURIComponent(
        searchTerm,
      )}&market-id=${marketId}&platform-id=Web&size=${SEARCH_RESULTS_LIMIT}&api-version=${API_VERSION_SEARCH}`,
      dispatch,
    ),
    SEARCH_DEBOUNCE_TIME,
  );
  searchDebouncer();
};
