import { generateActionNames } from '##/utils/redux';

export const SCOPE = 'GEOCOMPLY';

export const [
  SET_IN_PROGRESS,
  SET_DATA,
  SET_MARKET_DATA,
  SET_GEO_INTENT,
  SET_WITH_LOCATION,
  SET_IGNORE_LOCATION,
  CLEAR_CONNECT_TV,
  SET_CONNECT_TV_ERROR,
  CLEAR_CONNECT_TV_ERROR,
] = generateActionNames(
  SCOPE,
  'SET_IN_PROGRESS',
  'SET_DATA',
  'SET_MARKET_DATA',
  'SET_GEO_INTENT',
  'SET_WITH_LOCATION',
  'SET_IGNORE_LOCATION',
  'CLEAR_CONNECT_TV',
  'SET_CONNECT_TV_ERROR',
  'CLEAR_CONNECT_TV_ERROR',
);
