import { IMarket } from '##/interfaces/IMarket';

export const HOME_PAGE_TYPE = 'homePage';
export const SEARCH_PAGE_TYPE = 'searchPage';
// NOTE: 'BROADCAST_PAGE_TYPE' will soon be deleted,
//       once all 'LIVE' pages are migrated to be a 'listingPage'
export const BROADCAST_PAGE_TYPE = 'broadcastPage';
// NOTE: 'SHOW_PAGE_TYPE' will soon be deleted,
//       once all 'show pages' are migrated to be a 'listingPage'
export const SHOW_PAGE_TYPE = 'showPage';
export const LISTING_PAGE_TYPE = 'listingPage';

let appVersion = 0;

if (window.swm && window.swm.buildNumber) {
  appVersion = parseInt(window.swm.buildNumber, 10);
}

export const app = {
  version: appVersion,
};

export const ENV_PRODUCTION = 'Production';

export const env = {
  local: 'Local',
  develop: 'DevTest',
  testing: 'Testing',
  staging: 'Staging',
  production: ENV_PRODUCTION,
};

// ------------------------------------------------------------------------------------------------
// TODO: uncomment code below once our backend support handling negative numbers for marketid
// const NSW_MARKET_DATA = {
//   ID: 4,
//   POSTCODE: 2000,
// };
// const WA_MARKET_DATA = {
//   ID: 25,
//   POSTCODE: 6000,
// };
// const VIC_MARKET_DATA = {
//   ID: 26,
//   POSTCODE: 3000,
// };
// const SA_MARKET_DATA = {
//   ID: 28,
//   POSTCODE: 5000,
// };
// ------------------------------------------------------------------------------------------------
const DEFAULT_UNKNOWN_MARKET_ID = -1;
const DEFAULT_UNKNOWN_MARKET_POSTCODE = '1000';

// ------------------------------------------------------------------------------------------------
// TODO: uncomment code below once our backend support handling negative numbers for marketid
// const getDefaultMarketData = () => {
//   // multiplying by -1 since getTimezoneOffset returns a negative value
//   const timezoneUTC = ((new Date()).getTimezoneOffset / 60) * -1;

//   let DEFAULT_MARKET_ID;
//   let DEFAULT_MARKET_POSTCODE;
// eslint-disable-next-line max-len
//   // NOTE: the values below are being multiplied by -1 to get a negative market id and postcode to
//   //       indicate that's a calculated value
//   switch (timezoneUTC) {
//     case 11:
//       DEFAULT_MARKET_ID = NSW_MARKET_DATA.ID * -1;
//       DEFAULT_MARKET_POSTCODE = `${NSW_MARKET_DATA.POSTCODE * -1}`;
//       break;
//     case 10.5:
//       DEFAULT_MARKET_ID = SA_MARKET_DATA.ID * -1;
//       DEFAULT_MARKET_POSTCODE = `${SA_MARKET_DATA.POSTCODE * -1}`;
//       break;
//     case 10:
//       DEFAULT_MARKET_ID = VIC_MARKET_DATA.ID * -1;
//       DEFAULT_MARKET_POSTCODE = `${VIC_MARKET_DATA.POSTCODE * -1}`;
//       break;
//     case 8:
//       DEFAULT_MARKET_ID = WA_MARKET_DATA.ID * -1;
//       DEFAULT_MARKET_POSTCODE = `${WA_MARKET_DATA.POSTCODE * -1}`;
//       break;
//     default:
//       DEFAULT_MARKET_ID = DEFAULT_UNKNOWN_MARKET_ID;
//       DEFAULT_MARKET_POSTCODE = DEFAULT_UNKNOWN_MARKET_POSTCODE;
//   }

//   return { DEFAULT_MARKET_ID, DEFAULT_MARKET_POSTCODE };
// };

// const { DEFAULT_MARKET_ID, DEFAULT_MARKET_POSTCODE } = getDefaultMarketData();
// ------------------------------------------------------------------------------------------------
const DEFAULT_MARKET_ID = DEFAULT_UNKNOWN_MARKET_ID;
const DEFAULT_MARKET_POSTCODE = DEFAULT_UNKNOWN_MARKET_POSTCODE;

export const VIDEO_TYPE_LIVE = 'live';
export const VIDEO_TYPE_VOD = 'vod';

export const SignInMessageHtml =
  'Member features are on their way. Sign in to get started.';

export const MobileSignInMessageHtml = 'Sign in to get started';

export const DEFAULT_MARKET_DATA: IMarket = {
  market_id: DEFAULT_MARKET_ID,
  postcode: DEFAULT_MARKET_POSTCODE,
  suburb: '',
  user_market: '',
  timestamp: 0,
  verified: false,
};

export const DEFAULT_PAGE_TITLE = '7plus';

const fallbackEndpoints = {
  authService: 'https://staging-auth2.inferno.digital',
};
const configEndpoints =
  window.swm && window.swm.config && window.swm.config.endpoints;

export const endpoints = Object.assign(
  fallbackEndpoints,
  configEndpoints || {},
);

export const nodeEndpoints = {
  AUTH_OTP: '/auth/otp',
  AUTH_TOKEN: '/auth/token',
  USER_PROFILE: '/user/profile',
  DELETE_PROFILE: '/account/disable',
  LOGOUT_FROM_ALL_DEVICES: '/account/logout',
};

export const shelfLayouts = {
  grid: 'Grid Layout' as const,
  horizontal: 'Horizontal Shelf' as const,
  vertical: 'Vertical Layout' as const,
  movieCardLayout: 'Movie Card Layout' as const,
  portrait: 'Landscape Image' as const,
  landscape: 'Portrait Image' as const,
};
export const Classes = {
  UTIL_PREVENT_SCROLL: 'scroll-lock',
};

export const REG_SOURCE = '7plus';
export const WEB_PLATFORM_ID = 'web';
export const WEB_CTV_PLATFORM_ID = 'webctv';
export const DEFAULT_FETCH_TIMEOUT = 14e3; // 14 seconds

export const GDPR_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'GF',
  'GP',
  'MQ',
  'ME',
  'YT',
  'RE',
  'MF',
  'GI',
  'AX',
  'PM',
  'GL',
  'BL',
  'SX',
  'AW',
  'CW',
  'WF',
  'PF',
  'NC',
  'TF',
  'AI',
  'BM',
  'IO',
  'VG',
  'KY',
  'FK',
  'MS',
  'PN',
  'SH',
  'GS',
  'TC',
  'AD',
  'LI',
  'MC',
  'SM',
  'VA',
  'JE',
  'GG',
  'GI',
  'CH',
];

export default {
  app,
  env,
  endpoints,
  shelfLayouts,
  Classes,
};
