import { DEFAULT_MARKET_DATA } from '##/utils/constants';
import {
  getJsonValueFromLocalStorage,
  setJsonValueInLocalStorage,
} from '##/utils/storage';
import { IMarket } from '##/interfaces/IMarket';
import { logError } from '##/utils/trackJs';
import { getDeviceId } from '##/utils/tracking';

export const MARKET_DATA_KEY = 'marketData';

const {
  endpoints: { locationApi },
  marketIdExpirationSeconds: MARKET_ID_EXPIRATION,
} = window.swm.config;

const GEO_SOURCE_KEY = 'geolocation';
const IP_SOURCE_KEY = 'ip';

const interpolateLAPIEndpoint = (endpoint, deviceId, coords) => {
  if (coords) {
    endpoint = `${endpoint}?lat=${coords.latitude}&lon=${coords.longitude}`;
  }
  return endpoint.replace('{{deviceId}}', deviceId);
};

const getCurrentPosition = (options = undefined): Promise<Position> =>
  new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

const saveMarketData = (data: IMarket) => {
  //Setting the expiry time of market data
  data.timestamp = new Date().getTime() + MARKET_ID_EXPIRATION * 1000;

  setJsonValueInLocalStorage(MARKET_DATA_KEY, data);
};

const isMarketData = (obj: any): obj is IMarket =>
  obj && obj.timestamp > Date.now();

export const loadMarketData = (): IMarket | null => {
  const marketData = getJsonValueFromLocalStorage<IMarket>(MARKET_DATA_KEY);

  if (marketData && marketData._id !== undefined) {
    marketData.market_id = marketData._id;
    setJsonValueInLocalStorage(MARKET_DATA_KEY, marketData);
  }

  if (isMarketData(marketData)) {
    return marketData;
  }

  return null;
};

export const fetchMarketData = async (endpoint, source, coords?) => {
  const interpolatedEndpoint = interpolateLAPIEndpoint(
    endpoint,
    getDeviceId(),
    coords,
  );
  const response = await fetch(interpolatedEndpoint, {
    method: 'GET',
  });

  if (response.status === 404) {
    saveMarketData(DEFAULT_MARKET_DATA);

    return DEFAULT_MARKET_DATA;
  }

  const data = await response.json();
  const marketData = { ...data, source };

  saveMarketData(marketData);

  return marketData;
};

export const fetchMarketByIp = () =>
  fetchMarketData(
    `${locationApi}/devices/{{deviceId}}/location`,
    IP_SOURCE_KEY,
  );

export const fetchMarketByGeo = async () => {
  const { coords } = await getCurrentPosition();

  return fetchMarketData(
    `${locationApi}/devices/{{deviceId}}/location`,
    GEO_SOURCE_KEY,
    coords,
  );
};

export const getMarketData = () =>
  new Promise((resolve) => {
    const marketData = loadMarketData();

    if (marketData && marketData.timestamp) {
      return resolve(marketData);
    }

    return fetchMarketByIp()
      .then(resolve)
      .catch((error) => {
        logError('Failed to get market data', error);
        resolve(DEFAULT_MARKET_DATA);
      });
  });
